<template>
	<detail-page
		v-if="isLoaded"
		:ref="detailPageRefName"
		:formModel="model"
		:formSchema="schema"
		:headerTitle="headerTitle"
		tooltipMsg="pmanualofdetail3476"
		@saveForm="saveModel($event)"
		@closeDetail="redirectToTable()"
		@delete="openDeleteModal()"
	></detail-page>
</template>

<script>
import DetailPage from '@/components/general/detail-page';
import { detailPageMixin } from '@/components/general/detail-page.mixin';
import serviceCommon from '@/services/service/common.service';
import loading from '@/mixins/loading.mixin';
import { model, fields } from './currency-form.data';

export default {
	components: {
		DetailPage,
	},

	mixins: [loading, detailPageMixin],

	data() {
		return {
			controllerName: 'Currency',
			model,
			schema: {
				groups: fields,
			},
		};
	},

	computed: {
		headerTitle() {
			const title = this.$t('detailofcurrenc');
			const name = this.model.CurrencyNameSk || '';
			const id = this.$t('id');
			const idNum = this.model.CurrencyId;
			return `${title} ${name} ${id} ${idNum}`;
		},
	},

	methods: {
		openDeleteModal() {
			serviceCommon.openDeleteModal(this.model.CurrencyId, this.$modal, this.deleteModel);
		},
	},
};
</script>