import vueFormGenerator from 'vue-form-generator';

export const model = {
	CurrencyId: 0,
	CurrencyNameSk: null,
	CurrencyNameCz: null,
	CurrencyNamePl: null,
	CurrencyNameDe: null,
	CurrencyNameEn: null,
	CurrencyNameHu: null,
	CurrencyCode: null,
	CurrencyAbbr: null,
	CurrencyRate: null,
};

export const fields = [
	{
		fields: [
			{
				type: 'input',
				inputType: 'text',
				model: 'CurrencyNameSk',
				required: true,
				validator: vueFormGenerator.validators.required,
				i18n: {
					label: 'namesk2556',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'CurrencyNameCz',
				required: true,
				validator: vueFormGenerator.validators.required,
				i18n: {
					label: 'namecz2558',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'CurrencyNamePl',
				required: true,
				validator: vueFormGenerator.validators.required,
				i18n: {
					label: 'namepl2561',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'CurrencyNameEn',
				required: true,
				validator: vueFormGenerator.validators.required,
				i18n: {
					label: 'nameen2564',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'CurrencyNameDe',
				required: true,
				validator: vueFormGenerator.validators.required,
				i18n: {
					label: 'namede2562',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'CurrencyNameHu',
				required: true,
				validator: vueFormGenerator.validators.required,
				i18n: {
					label: 'namehu2566',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'CurrencyCode',
				required: true,
				validator: vueFormGenerator.validators.required,
				i18n: {
					label: 'currencycodedig',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'CurrencyAbbr',
				required: true,
				validator: vueFormGenerator.validators.required,
				i18n: {
					label: 'symbolabbreviat',
				},
			},
			{
				type: 'input',
				inputType: 'number',
				model: 'CurrencyRate',
				required: true,
				validator: [vueFormGenerator.validators.number],
				i18n: {
					label: 'exchangerateaga3479',
				},
			},
		],
	},
];
